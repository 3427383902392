import UserContext from '../../Components/UserContext'
import Header from '../../Components/HeaderAlt'
import './ApiDemo.css';
import { useContext } from 'react'
import { useState, useEffect } from "react"
import axios from 'axios';
import Select, { components } from 'react-select';
import toast, { Toaster } from 'react-hot-toast';


export default function ApiDemo(){
    const [selectData, setSelectData] = useState([])
    const [employee, setEmployee] = useState({})

    // Load data on page load
    useEffect(() => {
      fetchAllXrefCodes();
    }, [])

    const makeToast = (promise) => toast.promise(promise, {
      loading: 'Calling Dayforce API',
      success: 'Got the data',
      error: 'Error when fetching',
    });

    const getAPI = (path) => {
      const basicAuth = 'Basic ' + btoa('DFWSTest:DFWSTest'); // username = 'DFWSTest'; password = 'DFWSTest';

      const api = axios.create({
        baseURL: 'https://test.dayforcehcm.com/api/ddn/V1/',
        url: path,
        headers: { 'Authorization': basicAuth },
        maxRedirects: 1
      });
    
      api.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          if (error.response && error.response.status === 301) { // This never hits but it should ;-;
            const redirectUrl = error.response.headers.location;
            return axios.get(redirectUrl, { headers: { 'Authorization': basicAuth } });
          }

          if (error.response && error.response.status === 401 && !error.config.retry) {
            error.config.retry = true;
    
            // Change the base URL and path for the retry
            error.config.baseURL = error.request.responseURL;
            error.config.url = '';
            
            return api(error.config);
          }
    
          // Suppress the 401 error from being logged to the console
          if (error.response && error.response.status === 401) {
            return new Promise(() => {});
          }
    
          // Return the error if it's not a 401 or a retry has already been attempted
          return Promise.reject(error);
        }
      );
      
      return api.get(path); 
    };

    const fetchAllXrefCodes = async () => {
      await getAPI('/Employees')
      .then(function(response) {
        setSelectData(response.data);})
      .catch(function(error) {
          console.log('Error on Authentication');
      });
    };

    const fetchEmployeeDetails = async (xrefCode) => {        
        await getAPI('/Employees/' + xrefCode + 
        '?expand=Addresses,AuthorizationAssignments,CANFederalTaxes,CANStateTaxes,' +
        'CANTaxStatuses,ClockDeviceGroups,CompensationSummary,Contacts,Courses,' + 
        'DirectDeposits,DocumentManagementSecurityGroups,EIRates,EmergencyContacts,' +
        'EmployeeManagers,EmployeeProperties,EmployeePayAdjustCodeGroups,' +
        'EmployeeWorkAssignmentManagers,EmploymentAgreements,EmploymentStatuses,' +
        'EmploymentTypes,Ethnicities,GlobalProperties,GLSplits,HealthWellnessDetails,' +
        'HighlyCompensatedEmployees,HRIncidents,LaborDefaults,Locations,MaritalStatuses,' +
        'OnboardingPolicies,OrgUnitInfos,PayGradeRates,PerformanceRatings,Roles,Skills,' +
        'SSOAccounts,TrainingPrograms,UnionMemberships,UserPayAdjustCodeGroups,USFederalTaxes,' +
        'USStateTaxes,USTaxStatuses,WorkAssignments,WorkContracts')
        .then(function(response) {
          setEmployee(response.data.Data)
        }).catch(function(error) {
            console.log('Error on Authentication');
        });
    };

    const newEmployeesForCSV = [];
    const fetchEmployeeDetailsForCSV = async () => {    
      const promise = (async () => {
        for (const employee of selectedEmployees) {
            await getAPI('Employees/' + employee.value + 
            '?expand=Addresses,AuthorizationAssignments,CANFederalTaxes,CANStateTaxes,' +
            'CANTaxStatuses,ClockDeviceGroups,CompensationSummary,Contacts,Courses,' + 
            'DirectDeposits,DocumentManagementSecurityGroups,EIRates,EmergencyContacts,' +
            'EmployeeManagers,EmployeeProperties,EmployeePayAdjustCodeGroups,' +
            'EmployeeWorkAssignmentManagers,EmploymentAgreements,EmploymentStatuses,' +
            'EmploymentTypes,Ethnicities,GlobalProperties,GLSplits,HealthWellnessDetails,' +
            'HighlyCompensatedEmployees,HRIncidents,LaborDefaults,Locations,MaritalStatuses,' +
            'OnboardingPolicies,OrgUnitInfos,PayGradeRates,PerformanceRatings,Roles,Skills,' +
            'SSOAccounts,TrainingPrograms,UnionMemberships,UserPayAdjustCodeGroups,USFederalTaxes,' +
            'USStateTaxes,USTaxStatuses,WorkAssignments,WorkContracts')
            .then(function(response) {
              newEmployeesForCSV.push(response.data.Data);
            }).catch(function(error) {
                throw new Error('Error fetching employee details');
            });
        }
        })();

        makeToast(promise);

        await promise;

        const headers = ['XRefCode', 'First Name', 'Last Name', 'Address 1', 'Address 2', 'Address 3', 'Position', 'Pay Group', 'Base Rate', 'Skills'];
        
        const csvData = [
            headers,
            ...newEmployeesForCSV.map(employee => [
            employee.XRefCode,
            employee.FirstName,
            employee.LastName,
            employee.Addresses?.Items[0]?.Address1,
            employee.Addresses?.Items[0]?.Address2,
            employee.Addresses?.Items[0]?.Address3,
            employee.WorkAssignments?.Items[0]?.Position?.ShortName,
            employee.EmploymentStatuses?.Items[0]?.PayGroup?.ShortName,
            employee.EmploymentStatuses?.Items[0]?.BaseRate,
            '\"' + employee?.Skills?.Items?.map(x => x?.Skill?.ShortName).join(', ') + '\"',
            ]),
        ];

        const csvContent = csvData.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'employees.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [selectedOption, setSelectedOption] = useState(null);
    const SelectDropDown = () => {
        const options = selectData?.Data?.map((item) => ({
            value: item.XRefCode,
            label: `Employee ID: ${item.XRefCode}`,
          }));

          const customStyles = {
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected ? 'white' : 'black', // Change font color based on selection
            })
          };
          
        return (
            <div>
              <Select
                options={options}
                styles={customStyles}
                value={selectedOption}
                onChange={(e) => {
                    setSelectedOption(e);
                    makeToast(fetchEmployeeDetails(e.value));
                  }}
              />
            </div>
        )
    }

    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const SelectMultiple = () => {
        const options = selectData?.Data?.map((item) => ({
            value: item.XRefCode,
            label: `Employee ID: ${item.XRefCode}`,
          }));
        
          const Option = (props) => {
            return (
              <components.Option {...props}>
                <input
                  type="checkbox"
                  checked={props.isSelected}
                  onChange={() => null} // This is just to prevent a warning for read-only input
                  onClick={(e) => e.stopPropagation()} // Prevents option from being selected on checkbox click
                />{' '}
                {props.label}
              </components.Option>
            );
          };

          const customStyles = {
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected ? 'white' : 'black', // Change font color based on selection
            }),
            singleValue: (provided, state) => ({
              ...provided,
              color: 'black', // Change font color for selected value
            }),
            input: (provided, state) => ({
              ...provided,
              color: 'black', // Change font color for input text
            }),
          };
        
          return (
            <div>
              <Select
                isMulti
                options={options}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                styles={customStyles}
                value={selectedEmployees}
                onChange={setSelectedEmployees}
              />
            </div>
          );
    }

    const userData = useContext(UserContext);

    return(
        <>
            <Toaster />
            <Header />
            <div className="container">
              <h1>API Demo</h1>
              <br></br>
              <h3>Single employee info:</h3>
              <ul>
                  <SelectDropDown />
                  {employee.XRefCode && <li>XRefCode: {employee.XRefCode}</li>}
                  {employee.FirstName && <li>First Name: {employee.FirstName}</li>}
                  {employee.LastName && <li>Last Name: {employee.LastName}</li>}
                  {employee.Addresses?.Items[0]?.Address1 && <li>Address 1: {employee.Addresses?.Items[0]?.Address1}</li>}
                  {employee.Addresses?.Items[0]?.Address2 && <li>Address 2: {employee.Addresses?.Items[0]?.Address2}</li>}
                  {employee.Addresses?.Items[0]?.Address3 && <li>Address 3: {employee.Addresses?.Items[0]?.Address3}</li>}
                  {employee.WorkAssignments?.Items[0]?.Position?.ShortName && <li>Job Title: {employee.WorkAssignments?.Items[0]?.Position?.ShortName}</li>}
                  {employee.EmploymentStatuses?.Items[0]?.PayGroup?.ShortName && <li>Pay Group: {employee.EmploymentStatuses?.Items[0]?.PayGroup?.ShortName}</li>}
                  {employee.EmploymentStatuses?.Items[0]?.BaseRate && <li>Base Pay:  {employee.EmploymentStatuses?.Items[0]?.BaseRate}</li>}
                  {employee?.Skills?.Items && <li>Skills: {employee?.Skills?.Items?.map(x => x?.Skill?.ShortName).join(', ')}</li>}
              </ul>
              <h3>Multi employee info:</h3>
              <ul>
                  <SelectMultiple />
              </ul>
              <ul className='middle'>
                  <button onClick={fetchEmployeeDetailsForCSV}>Get CSV with Employee Info</button>
              </ul>
            </div>
            
        </>
    )
}