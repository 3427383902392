import React from 'react';

function About(props) {
  let name, profilepic, bio, street, city, state, zip, phone, email, resume;

  if (props.data) {
    name = props.data.name;
    profilepic = require(`../../../Assets/Images/${props.data.image}`);
    bio = props.data.bio;
    street = props.data.address.street;
    city = props.data.address.city;
    state = props.data.address.state;
    zip = props.data.address.zip;
    phone = props.data.phone;
    email = props.data.email;
    resume = require(`../../../Assets/${props.data.resumedownload}`);
  }

  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
          <img className="profile-pic" src={profilepic} alt="Michael Farmer Profile Pic" />
        </div>
        <div className="nine columns main-col">
          <h2>About Me</h2>

          <p>{bio}</p>
          <div className="row">
            <div className="columns contact-details">
              <h2>Contact Details</h2>
              <p className="address">
                <span>{name}</span><br />
                {/* <span>{street}<br />
                  {city} {state}, {zip}
                </span><br /> */}
                <span>{phone}</span><br />
                <span>{email}</span>
              </p>
            </div>
            <div className="columns download">
              <p>
                <a href={resume} className="button" target="_blank" rel="noopener noreferrer"><i className="fa fa-download"></i>Download Resume</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
