import React from 'react';
import './NotFoundPage.css';

function NotFoundPage() {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404</h1>
      <p className="not-found-text">Page Not Found</p>
      <a href="/" className="not-found-link">Go Home</a>
    </div>
  );
}

export default NotFoundPage;
