

import React, { useState } from 'react';
import './CustomPage.css';
import Header from '../../Components/HeaderAlt';
import BenefitTokens from './Components/BenefitTokens';
import PayRunTokens from './Components/PayRunTokens';
import HRTokens from './Components/HRTokens';
import GLRollingTokens from './Components/GLRollingTokens';
import TextSnippet from './Components/TextSnippets';
import TimeZoneConvert from './Components/TimeZoneConvert';
import { Toaster } from 'react-hot-toast';

const CustomPage = () => {
  const [activeSection, setActiveSection] = useState('timezoneconvert');
  
  return (
    <>
      <Toaster />
      <Header />
      <div className='container'>
        <h1>Various Things</h1>
        <div className="section-tabs">
          <button onClick={() => setActiveSection('timezoneconvert')} className={activeSection === 'timezoneconvert' ? 'active' : ''}>Time Zones</button>
          <button onClick={() => setActiveSection('benefits')} className={activeSection === 'benefits' ? 'active' : ''}>Benefit Tokens</button>
          <button onClick={() => setActiveSection('payruns')} className={activeSection === 'payruns' ? 'active' : ''}>Pay Run Tokens</button>
          <button onClick={() => setActiveSection('glpayroll')} className={activeSection === 'glpayroll' ? 'active' : ''}>GL Payroll Tokens</button>
          <button onClick={() => setActiveSection('hr')} className={activeSection === 'hr' ? 'active' : ''}>HR Tokens</button>
          <button onClick={() => setActiveSection('textsnippets')} className={activeSection === 'textsnippets' ? 'active' : ''}>Text Snippets</button>
        </div>
        {activeSection === 'timezoneconvert' && <TimeZoneConvert />}
        {activeSection === 'benefits' && <BenefitTokens />}
        {activeSection === 'payruns' && <PayRunTokens />}
        {activeSection === 'glpayroll' && <GLRollingTokens />}
        {activeSection === 'hr' && <HRTokens />}
        {activeSection === 'textsnippets' && <TextSnippet />}
      </div>
    </>
  );
};

export default CustomPage;
