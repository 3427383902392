import { useContext } from 'react'
import { useState, useEffect } from "react"
import UserContext from '../Components/UserContext'


export default function DesignExport(){
    const [selectData, setSelectData] = useState([])
    const [selectValue, setSelectValue] = useState([])

    // Load data on page load
    useEffect( () => {
            fetchData()
        }, []
    )

    const fetchData = async() => {
        await fetch('https://jsonplaceholder.typicode.com/users')
        .then(res => res.json())
        .then(data => setSelectData(data))
        .catch(err => console.log(err))
    }

    const SelectDropDown = () => {
        return (
            <select value={selectValue} onChange={(e) => setSelectValue(e.target.value)}>
                {
                    selectData?.map((item) => (
                        <option value={item.website} key={item.website}>{item.website}</option>
                    ))
                }
            </select>
        )
    }

    const userData = useContext(UserContext); // pull data from the parent context

    return(
        <>
            <h1>Design Export</h1>
            <ul>
                <li>Name: {userData.name}</li>
                <li>Email: {userData.email}</li>
                <li>LoggedIn: {userData.loggedIn}</li>
                <li>CartItems: {userData.cartItems}</li>
            </ul>
            <SelectDropDown />
        </>
    )
}