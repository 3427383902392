import React from 'react';
import { useState } from "react";

function Resume(props) {
  let work, education, certification, skills;
  const [showAllEducation, setShowAllEducation] = useState(false);
  const [showAllCertification, setShowAllCertification] = useState(false);
  const [showAllWork, setShowAllWork] = useState(false);

  if (props.data) {
    work = props.data.work.map((work, index) => (
      <div key={work.company} style={{ display: showAllWork || index < 2 ? 'block' : 'none' }}>
        <h3>{work.title}</h3>
        <p className="info">
          <a href={work.url} target="_blank">{work.company}</a>
          <span>&bull;</span> <em className="date">{work.years}</em>
        </p>
        <p>{work.description}</p>
      </div>
    ));

    education = props.data.education.map((education, index) => (
      <div key={education.degree} style={{ display: showAllEducation || index < 2 ? 'block' : 'none' }}>
        <h3>
            {education.degree}
            <em> {education.concentration}</em>
        </h3>
        <p className="info">
          <a href={education.url} target="_blank">{education.school}</a>
          <span>&bull;</span>
          <em className="date">{education.graduated}</em>          
        </p>
        <p>{education.description}</p>
      </div>
    ));
    
    certification = props.data.certification.map((certification, index) => (
      <div key={certification.certificate} style={{ display: showAllCertification || index < 2 ? 'block' : 'none' }}>
        <h3>
            {certification.certificate}
            <em> {certification.concentration}</em>
        </h3>
        <p className="info">
          <a href={certification.url} target="_blank">{certification.accreditor}</a>
          <span>&bull;</span>
          <em className="date">{certification.acquired}</em>
          {certification.downloadLink && 
            (<>
              <span>&bull;</span>
              <a href={require(`../../../Assets/${certification.downloadLink}`)} className="fa fa-download" target="_blank"></a>
            </>) || certification.validationLink && 
            (<>
              <span>&bull;</span>
              <a href={certification.validationLink} className="fa fa-download" target="_blank"></a>
            </>)
          }
          
        </p>
        <p>{certification.description}</p>
      </div>
    ));

    skills = props.data.skills.map(function (skills) {
      var className = 'bar-expand ' + skills.name.toLowerCase();
      return (
        <li key={skills.name}>
          <span style={{ width: skills.level }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });
  }

  return (
    <section id="resume">
    <div className="row work">
      <div className="three columns header-col">
        <h1>
          <span>Work</span>
        </h1>
      </div>
      <div className="nine columns main-col">
        <div className="row item">
          <div className="twelve columns">{work}</div>
          {work && work.length > 2 && (
            <div onClick={() => setShowAllWork(!showAllWork)} style={{ cursor: 'pointer' }}>
              <i className={`fa ${showAllWork ? 'icon-up-circle' : 'icon-down-circle'}`}></i>
            </div>
          )}
        </div>
      </div>
    </div>

      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
            {education && education.length > 2 && (
              <div onClick={() => setShowAllEducation(!showAllEducation)} style={{ cursor: 'pointer' }}>
              <i className={`fa ${showAllEducation ? 'icon-up-circle' : 'icon-down-circle'}`}></i>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row certification">
        <div className="three columns header-col">
          <h1>
            <span>Certifications</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{certification}</div>
            {certification && certification.length > 2 && (
              <div onClick={() => setShowAllCertification(!showAllCertification)} style={{ cursor: 'pointer' }}>
              <i className={`fa ${showAllCertification ? 'icon-up-circle' : 'icon-down-circle'}`}></i>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="bars">
            <ul className="skills">{skills}</ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Resume;
