import React from 'react';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';

function Contact(props) {

  const notify = function() {

      toast('Sorry! I\'m still debating on if I want emails essentially coming from my own website to me. I currently have it blocked on the backend! \r\n\r\nPlease reach out to me through LinkedIn or using my contact details listed instead.',
      {
        icon: '😔',
        duration: 15000,
        style: {
          borderRadius: '10px',
          border: '1px solid #fff',
          background: '#333',
          color: '#fff',
        },
      }
    );

    $('#image-loader').fadeIn();

    var contactName = $('#contactForm #contactName').val();
    var contactEmail = $('#contactForm #contactEmail').val();
    var contactSubject = $('#contactForm #contactSubject').val();
    var contactMessage = $('#contactForm #contactMessage').val();

    var data = 'contactName=' + contactName + '&contactEmail=' + contactEmail + '&contactSubject=' + contactSubject + '&contactMessage=' + contactMessage;

    $.ajax({

      type: "POST",
      url: "sendEmail.php",
      data: data,
      success: function(msg) {

          // Message was sent
          if (msg == 'OK') {
            $('#image-loader').fadeOut();
            $('#message-warning').hide();
            $('#contactForm').fadeOut();
            $('#message-success').fadeIn();   
          }
          // There was an error
          else {
            $('#image-loader').fadeOut();
            $('#message-warning').html(msg);
            $('#message-warning').fadeIn();
          }

      }

    });
    $('#image-loader').fadeOut();
    return false;
  };

  let name, street, city, state, zip, phone, email, message;

  if (props.data) {
    name = props.data.name;
    street = props.data.address.street;
    city = props.data.address.city;
    state = props.data.address.state;
    zip = props.data.address.zip;
    phone = props.data.phone;
    email = props.data.email;
    message = props.data.contactmessage;
  }

  return (
    <section id="contact">
      <div className="row section-head">
        <div className="two columns header-col">
          <h1><span>Contact Me.</span></h1>
        </div>
        <div className="ten columns">
          <p className="lead">{message}</p>
        </div>
      </div>

      <div className="row">
        <div className="eight columns">
          <form action="" method="post" id="contactForm" name="contactForm">
            <fieldset>
              <div>
                <label htmlFor="contactName">Name <span className="required">*</span></label>
                <input type="text" defaultValue="" size="35" id="contactName" name="contactName" />
              </div>

              <div>
                <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" />
              </div>

              <div>
                <label htmlFor="contactSubject">Subject</label>
                <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" />
              </div>

              <div>
                <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                <textarea rows="10" cols="50" id="contactMessage" name="contactMessage"></textarea>
              </div>

              <div>
                {/* <button className="submit">Submit</button> */}
                <span id="image-loader">
                  <img alt="" src={require(`../../../Assets/Images/loader.gif`)} />
                </span>
              </div>
            </fieldset>
          </form>
          
          <button onClick={notify} className="submit">Submit</button>

          <Toaster />
          <div id="message-warning">Error sending message</div>
          <div id="message-success">
            <i className="fa fa-check"></i>Your message was sent!<br />
          </div>
        </div>

        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4>Contact Details</h4>
            <p className="address">
              {name}<br />
              {/* {street} <br />
              {city}, {state} {zip}<br /> */}
              <span>{phone}</span><br />
              <span>{email}</span>
            </p>
          </div>
        </aside>
      </div>
    </section>
  );
}

export default Contact;
