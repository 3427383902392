import React, { useEffect, useState } from 'react';

function Header({ data }) {
  const [activeSection, setActiveSection] = useState('home');

  /* Highlight the current section in the navigation bar*/
  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'about', 'resume', 'portfolio', 'recommendations', 'contact'];
      const offsets = sections.map((section) => {
        const element = document.getElementById(section);
        return element ? element.offsetTop - window.innerHeight * 0.35 : 0;
      });

      const activeIndex = offsets.findIndex((offset, index) => {
        const nextOffset = offsets[index + 1] || Infinity;
        return window.scrollY >= offset && window.scrollY < nextOffset;
      });

      if (activeIndex !== -1) {
        setActiveSection(sections[activeIndex]);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Update the active section on initial load

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /* Smooth Scrolling */
  const smoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  let name, occupation, city, state, description, networks;

  if (data) {
    name = data.name;
    occupation = data.occupation;
    city = data.address.city;
    state = data.address.state;
    description = data.description;
    networks = data.social.map((network) => (
      <li key={network.name}>
        <a href={network.url} target="_blank">
          <i className={network.className}></i>
        </a>
      </li>
    ));
  }

  return (
    <header id="home">
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#no-nav-wrap" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
        <li className={activeSection === 'home' ? 'current' : ''}>
      <a className="smoothscroll" href="#home" onClick={(e) => { e.preventDefault(); smoothScroll('home'); }}>
              Home
            </a>
          </li>
          <li className={activeSection === 'about' ? 'current' : ''}>
            <a className="smoothscroll" href="#about"onClick={(e) => { e.preventDefault(); smoothScroll('about'); }}>
              About
            </a>
          </li>
          <li className={activeSection === 'resume' ? 'current' : ''}>
            <a className="smoothscroll" href="#resume"onClick={(e) => { e.preventDefault(); smoothScroll('resume'); }}>
              Resume
            </a>
          </li>
          <li className={activeSection === 'portfolio' ? 'current' : ''}>
            <a className="smoothscroll" href="#portfolio"onClick={(e) => { e.preventDefault(); smoothScroll('portfolio'); }}>
              Projects
            </a>
          </li>
          <li className={activeSection === 'recommendations' ? 'current' : ''}>
            <a className="smoothscroll" href="#recommendations"onClick={(e) => { e.preventDefault(); smoothScroll('recommendations'); }}>
              Recommendations
            </a>
          </li>
          <li className={activeSection === 'contact' ? 'current' : ''}>
            <a className="smoothscroll" href="#contact"onClick={(e) => { e.preventDefault(); smoothScroll('contact'); }}>
              Contact
            </a>
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          <h1 className="responsive-headline">{name}</h1>
          <h3>
            I'm a <span>{occupation}</span> based out of {city}, {state}.{' '}
            {description}.
          </h3>
          <hr />
          <ul className="social">{networks}</ul>
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
}

export default Header;
