import '../CustomPage.css';
import React, { useState, useEffect } from 'react';

const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => {
  const hours24 = Math.floor(i / 4);
  const hours12 = hours24 % 12 === 0 ? 12 : hours24 % 12;
  const minutes = (i % 4) * 15;
  const ampm = hours24 < 12 ? 'AM' : 'PM';
  return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
});

const timezoneOptions = {
  'EST': -5,
  'CST': -6,
  'MST': -7,
  'PST': -8
};

const TimeZoneConvert = () => {
  const [selectedTime, setSelectedTime] = useState(timeOptions[36]); // Default to 9:00 AM
  const [filteredTimes, setFilteredTimes] = useState([]);

  useEffect(() => {
    const selectedIndex = timeOptions.indexOf(selectedTime);
    const start = Math.max(0, selectedIndex); // start time
    const end = Math.min(timeOptions.length, selectedIndex + 9); // 2 hours after
    setFilteredTimes(timeOptions.slice(start, end));
  }, [selectedTime]);

  const convertTimeToTimezone = (time, timezoneOffset) => {
    const [time12, ampm] = time.split(' ');
    const [hours12, minutes] = time12.split(':').map(Number);
    const hours24 = (hours12 % 12) + (ampm === 'PM' ? 12 : 0);
    const convertedHours24 = (hours24 + timezoneOffset + 24) % 24;
    const convertedHours12 = convertedHours24 % 12 === 0 ? 12 : convertedHours24 % 12;
    const convertedAmPm = convertedHours24 < 12 ? 'AM' : 'PM';
    return `${convertedHours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${convertedAmPm}`;
  };

  return (
    <>
    <div className='timezoneconvert'>
      <div>
        <label>EST Time:</label>
        <select value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
          {timeOptions.map(time => <option key={time} value={time}>{time}</option>)}
        </select>
      </div>
      <table>
        <thead>
          <tr>
            {Object.keys(timezoneOptions).map(tz => <th key={tz}>Time ({tz})</th>)}
          </tr>
        </thead>
        <tbody>
          {filteredTimes.map(time => (
            <tr key={time}>
              {Object.values(timezoneOptions).map((offset, index) => (
                <td key={index}>{convertTimeToTimezone(time, offset - timezoneOptions['EST'])}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default TimeZoneConvert;
