import { useState, useEffect } from "react"
import axios from 'axios';
import Alert from '@mui/material/Alert';

export default function Contact(){
    const [email, setEmail] = useState('youremail@email.com')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')

    const [selectData, setSelectData] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [showAlert, setShowAlert] = useState(false);

    useEffect( () => {
        let processing = true
        axiosFetchData(processing)
            return () => {
                processing = false
            }
        }, []
    )

    useEffect(() => {
        if (showAlert) {
          const timer = setTimeout(() => {
            setShowAlert(false);
          }, 8000); // Hide the alert after 8 seconds
    
          return () => clearTimeout(timer);
        }
      }, [showAlert]);

    const axiosFetchData = async(processing) => {
        await axios.get('https://jsonplaceholder.typicode.com/users')
        .then(res => {
            if(processing){
                setSelectData(res.data)
            }
        })
    }

    const SelectDropDown = () => {
        return (
            <select value={selectValue} onChange={(e) => setSelectValue(e.target.value)}>
                {
                    selectData?.map((item) => (
                        <option value={item.website} key={item.website}>{item.website}</option>
                    ))
                }
            </select>
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(email + ' | ' + selectValue + ' | ' + message)
        if(!message){
            setError(<p className="required">Message is empty. Please type a message.</p>)
        }else{
            setError('')
            setShowAlert(true);
        }
    }

    return (
        <>
            <h1>Contact me</h1>
    
            <form className="contactForm">  
                <label>Email</label>
                <input type="text" id="email" name="email" value={email} onChange={ (e) => setEmail(e.target.value)} />

                <label>How did you hear about me?</label>
                <SelectDropDown />

    
                <label>Message</label>
                <textarea type="text" id="message" name="message" value={message} onChange={ (e) => setMessage(e.target.value)}/>
                {error}
    
                <button type="submit" onClick={handleSubmit}>Submit</button>
                {showAlert && (
                    <Alert variant="outlined" severity="success">This doesn't actually do anything... Sorry 😔</Alert>
                )}
            </form>
        </>
    )
}