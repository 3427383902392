import Header from '../Pages/Home/Components/Header'
import Footer from '../Pages/Home/Components/Footer'
import Home from '../Pages/Home/Home'
import Contact from '../Pages/Contact'
import DesignExport from '../Pages/DesignExport'
import ApiDemo from '../Pages/ApiDemo/ApiDemo'
import TextToSpeech from '../Pages/TextToSpeech/TextToSpeech'
import TextMods from '../Pages/TextMods/TextMods'
import NotFoundPage from '../Pages/NotFoundPage/NotFoundPage'
import CustomPage from '../Pages/CustomPage/CustomPage'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

export default function Router(){
    const Layout = () => {
        return(
            <>
                {/* <Header /> */}
                <Outlet />
                {/* <Footer /> */}
            </>
        )
    }

    const BrowserRoutes = () => {
        return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/designexport" element={<DesignExport />} />
                        <Route path="/apidemo" element={<ApiDemo />} />
                        <Route path="/texttospeech" element={<TextToSpeech />} />
                        <Route path="/textmods" element={<TextMods />} />
                        <Route path="/↑↑↓↓←→←→BA" element={<CustomPage />} />
                        <Route path='*' element={<NotFoundPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
        )
    }

    return(
        <>
          <BrowserRoutes />
        </>
      )
}