import React, { useState } from 'react';
import logo from '../Assets/Images/logo.png'
import UserContext from './UserContext'
import { useContext } from 'react'
import { Link } from 'react-router-dom';

export default function Header(){  
    const userData = useContext(UserContext);
    return(        
        
        <nav id="nav-wrap-alt">
        <a className="mobile-btn" href="#nav-wrap-alt" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#no-nav-wrap" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav-bar" className="nav-bar">
          <div className="logoblock">
            <li>
              <Link to="/"><img src={logo} alt="Logo" /></Link>
            </li>
          </div>
            <li>
              <Link target="_blank" to="https://www.google.com/search?q=what%27s+my+ip">User IP: {userData.ip}</Link>
            </li>
          <div className="linkblock">
            <li>
                <a href="/">Home</a>
            </li>
            <li>
                <a href="/apidemo">API Demo</a>
            </li>
            <li>
                <a href="/texttospeech">Text to Speech</a>
            </li>
            <li>
                <a href="/textMods">Text Mods</a>
            </li>
          </div>
        </ul>
      </nav>
    )
}