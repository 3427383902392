import Header from '../../Components/HeaderAlt'
import './TextToSpeech.css';
import { useState, useEffect } from "react"
import toast, { Toaster } from 'react-hot-toast';


export default function TextToSpeech(){
  const [text, setText] = useState('');
  const [isReading, setIsReading] = useState(false);
  const [rate, setRate] = useState(3);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState('');

  useEffect(() => {
    const availableVoices = window.speechSynthesis.getVoices();
    setVoices(availableVoices);
    if (availableVoices.length > 0) {
        setSelectedVoice(availableVoices[0].name);
    }
  }, []);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleRateChange = (event) => {
    setRate(event.target.value);
    if(isReading){
      window.speechSynthesis.cancel();
      toggleReadText();
    }
  };

  const handleVoiceChange = (event) => {
    setSelectedVoice(event.target.value);
    toast('Note: Not all voices are supported on all browsers',
      {
        icon: '❕',
        duration: 5000,
        position: 'bottom-center',
        style: {
          borderRadius: '10px',
          border: '1px solid #fff',
          background: '#333',
          color: '#fff',
        },
      }
    );
    
    if(isReading){
      window.speechSynthesis.cancel();
      toggleReadText();
    }
  };

  const toggleReadText = () => {
    if (isReading) {
      window.speechSynthesis.cancel();
      setIsReading(false);
    } else {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.rate = rate;
      const selectedVoiceObj = voices.find(voice => voice.name === selectedVoice);
      if (selectedVoiceObj) {
        utterance.voice = selectedVoiceObj;
      }
      utterance.onend = () => setIsReading(false);
      window.speechSynthesis.speak(utterance);
      setIsReading(true);
    }
  };

  return(
    <>
      <Toaster />
      <Header />
      <div className="text-reader-container">
        <h1>Text to Speech</h1>
        <br></br>
        <textarea
          value={text}
          onChange={handleTextChange}
          placeholder="Enter text to read"
          rows="10"
          cols="50"
        ></textarea>
        <div className="controls">
          <label htmlFor="rate">Speech Rate: {rate}x</label>
          <input
            type="range"
            id="rate"
            min="0.5"
            max="5"
            step="0.1"
            value={rate}
            onChange={handleRateChange}
          />
        </div>
        <div className="controls">
          <label htmlFor="voice">Voice:</label>
          <select id="voice" value={selectedVoice} onChange={handleVoiceChange}>
            {voices.map((voice, index) => (
              <option key={index} value={voice.name}>
                {voice.name}
              </option>
            ))}
          </select>
        </div>
        <button onClick={toggleReadText}>{isReading ? 'Stop' : 'Read Text'}</button>
      </div>
    </>
    )
}