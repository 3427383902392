import './App.css';
import Router from './Components/Router'
import UserContext from './Components/UserContext';
import { useState, useEffect } from "react";
import axios from "axios";

export default function App() {

  const [ip, setIP] = useState("");
  const getData = async () => {
    // const res = await axios.get("https://geolocation-db.com/json/");
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getData();
  }, []);
  
  const userInfo = {
    name: "Michael",
    email: "emailaddress@email.com",
    loggedIn: true,
    cartItems: 4,
    ip: ip
  }

  return (
    <>
      <UserContext.Provider value={userInfo}>
        <Router />
      </UserContext.Provider>
    </>
  );
}
