import Header from '../../Components/HeaderAlt'
import './TextMods.css';
import { useState } from "react"
import toast, { Toaster } from 'react-hot-toast';

export default function TextMods() {

  const [originalText, setOriginalText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [removeText, setRemoveText] = useState("");
  const [replaceText, setReplaceText] = useState("");
  const [leftPadText, setLeftPadText] = useState("");
  const [rightPadText, setRightPadText] = useState("");
  const [selectedTextStyle, setSelectedTextStyle] = useState('Line Break');
  const [removeWhitespace, setRemoveWhitespace] = useState(false);

  const handleRemoveWhitespaceChange = (event) => {
    setRemoveWhitespace(!removeWhitespace);
    replaceTextExecute(originalText, selectedTextStyle, leftPadText, rightPadText, removeText, replaceText, !removeWhitespace);
  };


  const handleOriginalTextChange = (event) => {
    const newText = event.target.value;
    setOriginalText(newText);
    replaceTextExecute(newText, selectedTextStyle, leftPadText, rightPadText, removeText, replaceText, removeWhitespace);
  };

  const handleRemoveTextChange = (event) => {
    const newText = event.target.value;
    setRemoveText(newText);
    replaceTextExecute(originalText, selectedTextStyle, leftPadText, rightPadText, newText, replaceText, removeWhitespace);
  };

  const handleReplaceTextChange = (event) => {
    const newText = event.target.value;
    setReplaceText(newText);
    replaceTextExecute(originalText, selectedTextStyle, leftPadText, rightPadText, removeText, newText, removeWhitespace);
  };

  const handleLeftPadTextChange = (event) => {
    const newText = event.target.value;
    setLeftPadText(newText);
    replaceTextExecute(originalText, selectedTextStyle, newText, rightPadText, removeText, replaceText, removeWhitespace);
  };

  const handleRightPadTextChange = (event) => {
    const newText = event.target.value;
    setRightPadText(newText);
    replaceTextExecute(originalText, selectedTextStyle, leftPadText, newText, removeText, replaceText, removeWhitespace);
  };

  const handleSelectedTextStyleChange = (event) => {
    const newTextStyle = event.target.value;
    setSelectedTextStyle(newTextStyle);
    replaceTextExecute(originalText, newTextStyle, leftPadText, rightPadText, removeText, replaceText, removeWhitespace);
  };
  
  const replaceTextExecute = (originalText, selectedTextStyle, leftPadText, rightPadText, removeText, replaceText, removeWhitespace) => {
    let textArray = null;
    if (selectedTextStyle === 'Line Break') {
      textArray = originalText.split('\n');
    } else if (selectedTextStyle === 'Tabs') {
      textArray = originalText.split('\t');
    }else if (selectedTextStyle === 'Commas') {
      textArray = originalText.split(',');
    } else {
        const tempOutput = originalText.replaceAll(removeText, replaceText);
        setOutputText(`${leftPadText}${tempOutput}${rightPadText}`);
        return;
    }

    const transformedArray = textArray
      .map(line => {
        // Replace text
        let tempOutput = line.replaceAll(removeText, replaceText);
        // Trim the output if needed
        if (removeWhitespace) tempOutput = tempOutput.trim();
        // Add LeftPadText and RightPadText to the replaced line
        return `${leftPadText}${tempOutput}${rightPadText}`;
      })
      // Remove lines that are empty when removeWhitespace is true
      .filter(line => !removeWhitespace || line.trim() !== ''); 

    setOutputText(transformedArray.join('\n'));
  };

  return (
    <>
      <Toaster />
      <Header />
      <div className="text-mod-container">
        <h1>Modify Text</h1>
        <br />
        <div className="textarea-container">
          <textarea value={originalText}
            onChange={handleOriginalTextChange}
            placeholder="Enter original text"
            rows="10"
            cols="50">
          </textarea>
          <textarea value={outputText} readOnly
            placeholder="Output text here"
            rows="10"
            cols="50">
          </textarea>
        </div>
        <br />
        <div className="input-container">
          <label>
            Line Breaks On:
            <select id="fileFormat" value={selectedTextStyle} onChange={handleSelectedTextStyleChange}>
              <option value="Line Break">Line Break</option>
              <option value="Tabs">Tabs</option>
              <option value="Commas">Commas</option>
            </select>
          </label>
          <label>
            <input
              type="checkbox"
              checked={removeWhitespace}
              onChange={handleRemoveWhitespaceChange}
            />
            Remove Whitespace?
          </label>
        </div>
        <br />
        <div className="input-container">
          <label>
            Remove Text
            <input type="text" 
              value={removeText}
              onChange={handleRemoveTextChange}
              placeholder="Enter text to remove" />
          </label>
          <label>
            Replace Text
            <input type="text"
              value={replaceText}
              onChange={handleReplaceTextChange}
              placeholder="Enter text to replace" />
          </label>
        </div>
        <br />
        <div className="input-container">
          <label>
            Pad Left
            <input type="text" 
              value={leftPadText}
              onChange={handleLeftPadTextChange}
              placeholder="Enter text to pad left" />
          </label>
          <label>
                Pad Right
            <input type="text"
              value={rightPadText}
              onChange={handleRightPadTextChange}
              placeholder="Enter text to pad right" />
          </label>
        </div>
        <br />
      </div>
    </>
  );
}
