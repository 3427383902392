import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
import Recommendations from './Components/Recommendations';
import Portfolio from './Components/Portfolio';

function Home() {
  const [resumeData, setResumeData] = useState({});

  useEffect(() => {
    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);

    const getResumeData = () => {
      $.ajax({
        url: '/resumeData.json',
        dataType: 'json',
        cache: false,
        success: function (data) {
          setResumeData(data);
        },
        error: function (xhr, status, err) {
          console.log(err);
          alert(err);
        },
      });
    };

    getResumeData();
  }, []);

  return (
    <div>
      <Header data={resumeData.main} />
      <About data={resumeData.main} />
      <Resume data={resumeData.resume} />
      <Portfolio data={resumeData.portfolio} />
      <Recommendations data={resumeData.recommendations} />
      <Contact data={resumeData.main} />
      <Footer data={resumeData.main} />
    </div>
  );
}

export default Home;
